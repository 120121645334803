import { filter, keyBy, mapValues, map, forEach, keys, find, includes } from 'lodash';
import Mustache from 'mustache';

export const parseField = (field) => {
  if (field.type && field.type.startsWith('Json')) {
    return parseJson(field.value);
  } else {
    return field.value;
  }
};

const parseJson = (value) => {
  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
};

export const parseFields = (fields) => {
  const parsed = map(fields, (field) => ({
    ...field,
    value: parseField(field)
  }));
  return parsed;
};

export const getFieldsForContent = (block, culture) => {
  const filtered = filter(block.content, (content) => content.culture === culture);
  if (filtered.length >= 1) {
    return parseFields(filtered[0].fields);
  }
  return null;
};

export const getKeyValueContentFieldsForBlock = (block, culture) => {
  const content = getFieldsForContent(block, culture);
  return mapValues(keyBy(content, 'name'), 'value');
};

export const isBrowserSupported = () => {
  const { detect } = require('detect-browser');
  const browser = detect();
  const name = browser.name;

  if (name === 'ie') {
    return false;
  } else {
    return true;
  }

  let supported;
  switch (name) {
    case 'chrome':
    case 'firefox':
    case 'edge':
    case 'safari':
    case 'ios':
    case 'crios':
      supported = true;
      break;
    default:
      supported = false;
  }
  return supported;
};

export const mergeText = (text, textToAdd, separator = '|') => {
  if (text.length > 0 && separator !== undefined) {
    if (text.includes(separator)) {
      return `${text} ${textToAdd}`;
    } else {
      return `${text} ${separator} ${textToAdd}`;
    }
  }

  return textToAdd;
};

export const hasWindow = () => {
  let hasWindow = false;
  try {
    if (window) {
      hasWindow = true;
    }
  } catch (ex) {
    hasWindow = false;
  }

  return hasWindow;
};

export const parseText = (text, data) => {
  if (data) {
    return Mustache.render(text, data);
  }
  return text;
};

export const defaultPageNames = {
  home: '/',
  vehicles: 'stock',
  vehicleDetail: 'vehicle',
  vehicleTakeOver: 'takeover',
  vehicleCompare: 'vehicle-compare',
  vehicleSearchAgent: 'search-agent',
  vehicleReservation: 'reservation',
  search: 'search',
  contact: 'contact',
  contactDetail: 'contact',
  services: 'services',
  about: 'about',
  privacy: 'privacy',
  blog: 'blog',
  blogPost: 'blog-post',
  favorites: 'favorites',
  login: 'login',
  registration: 'registration',
  forgotPassword: 'forgot-password',
  resetPassword: 'reset-password',
  financingInformation: 'financing-information',
  account: 'account',
  accountOverview: 'account-overview',
  accountProfile: 'account-profile',
  accountChangePassword: 'account-change-password',
  accountCarAgent: 'account-car-agent',
  accountFavorites: 'account-favorites',
  404: '404'
};

export const PageTypes = keys(defaultPageNames).reduce((a, v) => ({ ...a, [v]: v }), {});

export const resolveUrlsMultiLanguage = (pages) => {
  // Used for BW compatible, but depricated
  let result = {};
  forEach(keys(defaultPageNames), (pageType) => {
    const pagesInDb = filter(pages, (p) => p.type === pageType || (p.type === 'index' && pageType === 'home'));
    const defaultPageValue = defaultPageNames[pageType];

    if (pagesInDb.length > 0) {
      forEach(pagesInDb, (p) => {
        let translations = JSON.parse(p.slug);

        forEach(keys(translations), (lng) => {
          if (translations[lng] === '' && defaultPageValue !== '/') {
            translations[lng] = defaultPageValue;
          }
        });

        result[p.url] = translations;
      });
    } else {
      result[defaultPageValue] = {
        en: defaultPageValue,
        nl: defaultPageValue,
        fr: defaultPageValue,
        de: defaultPageValue
      };
    }
  });

  const customPages = filter(pages, (p) => p.type === 'custom');
  forEach(customPages, (page) => {
    const defaultPageValue = page.url;
    let translations = JSON.parse(page.slug);

    forEach(keys(translations), (lng) => {
      if (translations[lng] === '') {
        translations[lng] = defaultPageValue;
      }
    });
    result[defaultPageValue] = translations;
  });

  return result;
};

export const tryParse = (value) => {
  try {
    return JSON.parse(value);
  } catch (ex) {
    return value;
  }
};

export const resolveTranslationBySlug = (page, lng) => {
  const slug = tryParse(page.slug);
  if (slug && slug[lng] && slug[lng] !== '') {
    return slug[lng];
  } else {
    return page.url;
  }
};

export const parsePage = (page, multiLanguage, lng = undefined, data) => {
  let result = page.url;

  if (page.migration) {
    // if a page is a migration page, return the exact string from the slug
    // F.e blogposts of delorge must be exact the same example /first-blogpost an not /nl/blog/first-blogpost (our structure)

    const slug = tryParse(page.slug);
    if (slug && slug[lng] && slug[lng] !== '') {
      return `/${slug[lng]}`;
    } else {
      return undefined;
    }
  } else {
    // if page has slug --> url is the slug else url is de page-name
    const slug = tryParse(page.slug);
    if (slug && slug[lng] && slug[lng] !== '') {
      if (page.type === PageTypes.blogPost) {
        result = `blog/${slug[lng]}`;
      } else if (page.type === PageTypes.vehicles && includes(page.url, '{{')) {
        // dyanmic vehicle seo page  /aanbod/audi/a4
        // fisrt part is the page slug
        const pagePart = page.url.substring(0, page.url.indexOf('/'));
        result = page.url.replace(pagePart, slug[lng]);
      } else {
        result = slug[lng];
      }
    }
  }

  result = multiLanguage ? `/${lng}/${result}` : `/${result}`;

  if (result && result.indexOf('{{') > -1) {
    result = parseText(result, data);
  }

  if (data && data.dealerGroup && result.indexOf('dealerGroup') > -1) {
    result = result.replace('dealerGroup', data.dealerGroup.key);
  }

  if (result.indexOf('index') > -1) {
    result = result.replace('index', '');
  }

  // matchpath for pages with variables f.ex /page-x/:make/:model/:reference
  if (page.matchPath && page.matchPath !== '') {
    result = result + page.matchPath;
  }

  if (result === '') {
    result = '/';
  } else if (result !== '/' && !result.match(/\/$/)) {
    // result = `${result}/`; // trailing slash
  }

  return result;
};

export const isDynamicPage = (url) => {
  return url.indexOf('{{') > -1 || includes(url, 'dealerGroup');
};

export const b64DecodeUnicode = (str) => {
  try {
    return decodeURIComponent(
      atob(str)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    ).replace(/&nbsp;/g, ' ');
  } catch (e) {
    return undefined;
  }
};

export const mapArrayToString = (arr, separator = ',') => {
  let result = '';
  forEach(arr, (item) => {
    if (result === '') {
      result = item;
    } else {
      result = `${result}${separator}${item}`;
    }
  });
  return result;
};
