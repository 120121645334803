export const customerSettings = {
COMPANY_CODE: "dheane",
MODE: "production",
VERSION: 1.1,
DOMAIN: 'dheane.production2.digex.be',
CMS: 'https://cms.dheane.production2.digex.be',
CMS_USERNAME: "gatsby",
CMS_PASSWORD: "AutralisGatsby@2020",  
HEAD_JAVASCRIPT_URLS: [],
BODY_JAVASCRIPT_URLS: [],
LANGUAGES: ['nl'],
FORCE_SINGLE_LANGUAGE_IN_URL: false,

GOOGLE_ANALYTICS_IDENTIFICATION: '',
GOOGLE_ANALYTICS_ID: '',
GOOGLE_TAG_MANAGER_ID: '',
HOTJAR_ID: '',
MAPBOX_TOKEN: 'pk.eyJ1IjoiYXV0cmFsaXMiLCJhIjoiY2toeXR2emhwMDk0OTJ6cWh0Y3JjeG5kaSJ9.YCvAWa5Wyqof0wPdueve5w',
RECAPTCHA_KEY: '6LcZyaUZAAAAAOrMBxDUfdVDq2xKcHORceibmov3',
INTERCOM_ID: '',
FINANCING: false,
FINANCING_SETTINGA: 'Example',
FINANCING_TEXT: false,
RESERVATION: false,
FAVORITES: false,
VEHICLE_COMPARE: false,
VEHICLE_SEGMENTATION: false,
SEO: false,
ACCOUNT: false
};