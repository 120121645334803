import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import {DataContextProvider} from 'dealer-website-components';
import {defaultPageNames, resolveUrlsMultiLanguage} from "../utils";
import {default as customerSettings} from '../../config';

export default (props) => {
    const query = useStaticQuery(graphql`
    query MyQuery {
      cms {
        pages {
          url
          type
          slug
        }
      }
    }
  `)

    const themeConfig = {
        urls: defaultPageNames,
        urlsMultiLanguage: resolveUrlsMultiLanguage(query.cms.pages),
        settings: {...customerSettings}
    }

    return (
        <DataContextProvider themeConfig={themeConfig}>
            {props.children}
        </DataContextProvider>
    );
};

