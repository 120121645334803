export const defaultSettings = {
  COMPANY_CODE: '',
  MODE: 'production',
  VERSION: 1.0,
  DOMAIN: 'localhost:8000',

  TOKEN_EXPERT: '',
  CMS: 'localhost:8000',
  CMS_USERNAME: '',
  CMS_PASSWORD: '',

  GOOGLE_ANALYTICS_IDENTIFICATION: '',
  GOOGLE_ANALYTICS_ID: '',
  GOOGLE_TAG_MANAGER_ID: '',
  HOTJAR_ID: '',
  INTERCOM_ID: '',

  GOOGLE_SEARCH_CONSOLE_SITE_VERIFICATION: '',
  GDDL_ID: '', // google tag stuf dieteren
  CAPTCHA_KEY: '6LcZyaUZAAAAAOrMBxDUfdVDq2xKcHORceibmov3',

  FINANCING: false,
  RESERVATION: false,
  FAVORITES: false,
  FINANCING_TEXT: true,
  VEHICLE_COMPARE: false,
  VEHICLES_BASED_ON_URL: false,
  VEHICLE_SEGMENTATION: false,
  VEHICLES_PRELOAD: false,
  DEALER_GROUPS: false, // Used for myway to generate subpages for each dealergroup (LMA)
  SEO: false,
  ACCOUNT: false,

  CONSENT: true, // our default consent popup
  CONSENT_SCRIPT_URL: null,
  CONSENT_COOKIE_NAME: 'GDPR_ANALYTICS', // our (autralis) cookie banner, cookie name

  HEAD_JAVASCRIPT_URLS: [], // script urls to inlcude
  BODY_JAVASCRIPT_URLS: [], // script urls to inlcude

  // Example 'engineCc;desc' can be used
  DEFAULT_VEHICLES_SORT: '',

  MAPBOX_TOKEN: 'pk.eyJ1IjoiYXV0cmFsaXMiLCJhIjoiY2toeXR2emhwMDk0OTJ6cWh0Y3JjeG5kaSJ9.YCvAWa5Wyqof0wPdueve5w',

  LANGUAGES: ['nl', 'fr'],
  FORCE_SINGLE_LANGUAGE_IN_URL: false // when only one language, for to appear in the url /nl or /fr for example
};

// Google analytics demey: UA-162310885-2
// Google analytics phlips: UA-162310885-3
// hotjar demey: 1749511
// hotjar phlips: 1749519
